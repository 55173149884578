.search-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #D0D5DD; /* Border rengi */
    border-radius: 8px; /* Köşe yuvarlama */
    padding: 8px; /* İç boşluk */
    width: 100%; /* Genişlik ayarlanabilir */
    max-width: 300px; /* Maksimum genişlik */
}

.search-icon {
    color: #667085; /* İkon rengi */
    margin-right: 8px; /* İkon ile input arasında boşluk */
    font-size: 18px; /* İkon boyutu */
}

.search-input {
    border: none; /* Input'ta ekstra border olmasın */
    outline: none; /* Tıklanınca mavi border olmasın */
    width: 100%; /* Input genişliği */
    font-size: 14px; /* Yazı boyutu */
}

.search-input::placeholder {
    color: #667085; /* Placeholder rengi */
}
