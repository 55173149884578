.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    position: relative; /* Pseudo element için gerekli */
  }
  
  /* Seçili durum */
  .custom-checkbox:checked {
    background-color: rgba(218, 56, 49, 1);
    border: none;
  }
  
  /* Seçili durumda tik eklemek için */
  .custom-checkbox:checked::after {
    content: "";
    position: absolute;
    left: 5px; /* Konumlandırmayı ihtiyaca göre ayarlayın */
    top: 2px;  /* Konumlandırmayı ihtiyaca göre ayarlayın */
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  