body {
  background-color: #FAF6F5;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  padding-top:5px;
  width: 100vw;
}

p {
  font-family: "DM Sans", sans-serif;
}

.main {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .main {
    width: 80%;
  }
}

.button {
  background-color: #DA3831;
  border-width: 0;
  width: 100%;
  height: 54px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
  display: flex;
}

.button p {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #FAF6F5;
}

.button:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.7;
}

.button:disabled:hover {
  background-color: gray;
}

.button:hover {
  background-color: #B8302A;
  cursor: pointer;
}

.custom-carousel {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.carousel-image-container {
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
