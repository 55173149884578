.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none;
}
