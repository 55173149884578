.table-container {
    overflow-x: auto;
    width: 100%;
    max-height: 45vh;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table thead th {
    background-color: #EAECF0;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
    text-align: left;
    font-weight: 500;
    color: #475467;
    font-size: 12px;
    min-width: 100px;
    position: sticky;
    top: 0;
}

.custom-table th,
.custom-table td {
    border-bottom: 1px solid #e0e0e0;
    padding: 8px;
    color: #475467;
    font-size: 12px;
    font-weight: 400;
    min-width: 100px;
}

.custom-table tbody tr:hover {
    background-color: #f9f9f9;
}

.custom-table td {
    text-align: left;
    min-width: 100px;
}

.sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    color: #475467;
    /* İkon rengi */
    font-size: 12px;
    /* İkon boyutu */
}